import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import env from '../env.json';

interface CustomErrorProps {
  error?: Error;
  message?: string;
  name: string;
  status: number;
}
export function initSentry() {
  Sentry.init({
    dsn: 'https://1fd251baf77c93dc9bd7e39b698f5147@o4508456417427456.ingest.us.sentry.io/4508575199395840',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: env.name,
  });
}

export function sendCustomSentryError(e: CustomErrorProps) {
  Sentry.withScope((scope) => {
    const error = e.error || new Error(e.message || 'An unknown error occurred');
    error.name = e.name;
    scope.setContext('response', {
      status: e?.status,
    });
    Sentry.captureException(error);
  });
}
