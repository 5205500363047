import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { initSentry } from './utils/sentryUtility.ts';

initSentry();

const Main = lazy(() => import('./main'));
const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<Main />} />));
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
